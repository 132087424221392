import process from "process";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import { ErrorTracker } from './ErrorTracker';

// ------------------------------------------------------------
// BugSnagのエラートラッカークラス
// このクラスは、ErrorTrackerのインターフェースを実装している
// BugSnagにエラーをトラッキングする機能を持つ。
// -------------------------------------------------------------
class BugSnagErrorTracker implements ErrorTracker {
  apikey: string = process.env.NEXT_PUBLIC_BUGSNAG_API_KEY || ''

  /*
    * BugSnagの初期化
   */
  init(): void {
    // BugSnagのapikeyが設定されている場合のみ初期化する
    if (this.apikey !== '') {
      // init BugSnag
      const version = process.env.NEXT_PUBLIC_GIT_REV || '1.0.0'
      const envNotify = process.env.NEXT_PUBLIC_BUGSNAG_NOTIFY_ENDPOINT || ''
      const envSessions = process.env.NEXT_PUBLIC_BUGSNAG_SESSIONS_ENDPOINT || ''
      const baseOption = {
        apiKey: this.apikey,
        appVersion: version,
        plugins: [new BugsnagPluginReact()]
      }
      // セッションと通知のエンドポイントがある場合は設定する
      const startOption = (envNotify !== '' && envSessions !== '') ? {
        ...baseOption,
        endpoints: {
          notify: envNotify,
          sessions: envSessions
        }
      } : baseOption

      Bugsnag.start(startOption)
    }
  }

  /* Bugsnagへエラーログを送信
    * @param {string} name - エラー名
    * @param {any} data - エラー情報
    * @param {string} pjkey - プロジェクトキー
    * @param {string} visitorId - 訪問者ID
    * @param {string} sessionId - セッションID
    * @param {string} siteName - サイト名
    * @param {any} message - エラーメッセージ
    * @param {string} section - セクション
    * @param {string} version - バージョン
   */
  public sendLog = (
    logName: string,
    data: string,
    pjkey:string = '',
    visitorId:string = '',
    sessionId:string = '',
    siteName:string = '',
    message:any = {
      "name": "Error",
    },
    section = 'context',
    version = '1.0.0'
  ) => {
    const NEXT_PUBLIC_GIT_REV = process.env.NEXT_PUBLIC_GIT_REV || version
    // BugSnagのapikeyが設定されている場合のみエラーログを送信する
    if (this.apikey !== '') {
      // Bugsnagへ送信する
      Bugsnag.notify(message, (event) => {
        // 追加のメタデータや情報をレポートに含める
        event.addMetadata(section, {
          logName,
          logData: data,
          pjkey,
          siteName,
          visitorId,
          sessionId,
          version: NEXT_PUBLIC_GIT_REV,
          message,
        });
      });
    }
  }
}

export default BugSnagErrorTracker;
