import _ from 'lodash';

/*
  * ネストされたプロパティがあるかどうかを確認
  * @param {Record<string, any>} obj - チェックするオブジェクト
  * @param {string} path - 値をチェックしたいオブジェクトのパス
  * @return {boolean}
 */
export const hasNestedProperty = (
  obj: Record<string, any>,
  path: string
): boolean => {
  const nestedValue = _.get(obj, path);
  // 値が見つからない場合はfalseを返す
  return nestedValue !== undefined;
}