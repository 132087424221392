// components/ErrorBoundary.tsx
import React, { Component, ErrorInfo, ReactNode } from 'react';

// layouts
import ErrorAlertDialog from "@/components/custom/molecules/ErrorAlertDialog";
import {ErrorLogsHelper} from "@/utils/custom/ErrorLogsHelper";

//
interface ErrorBoundaryProps {
  logsHelper: ErrorLogsHelper;
  pjKey: string;
  children: ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
}

// ----------------------------------------------------------------------
// 目的：Reactコンポーネントのレンダリングエラーをキャッチしする
// 機能：エラー画面の表示、エラーログをErrorLogsHelperクラスに渡し送信する
// ----------------------------------------------------------------------
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  private pjKey: string;

  private logsHelper: ErrorLogsHelper;

  constructor(props: ErrorBoundaryProps) {
    super(props);
    //
    this.logsHelper = props.logsHelper;

    //
    this.pjKey = props.pjKey;
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(_: Error): ErrorBoundaryState {
    // エラーが発生した時にstateを更新します
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {

    // エラー情報を確認
    console.error('Uncaught error:', error, errorInfo);

    // エラー情報を外部のロギングサービスに送信
    this.logsHelper.sendLog('Application Render Error', errorInfo, this.pjKey, error)
  }

  render() {
    // デストラクチャリングを適用
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      // エラーが発生した際にフォールバックUIを表示
      return (
        <ErrorAlertDialog error={2} sx={{ mt: 3, ml: 3 }} />
      );
    }

    return children;
  }
}

export default ErrorBoundary;