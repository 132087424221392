import { ErrorTracker } from './ErrorTracker';

// ------------------------------------------------------------
// ダミーのエラートラッカークラス
// このクラスは、ErrorTrackerのインターフェースを実装しているが、
// 実際にエラーをトラッキングする機能は持っていない。送信オフ時の代替として利用する。
// -------------------------------------------------------------
class DummyErrorTracker implements ErrorTracker {
  apikey: string = ''

  init(): void {
    console.log(`dummy init function with apikey: ${this.apikey}`)
  }

  sendLog (
    logName: string='',
    data:string='',
    pjkey:string = '',
    visitorId:string = '',
    sessionId:string = '',
    siteName:string = '',
    message:any = {
      "name": "Error",
    },
    section = 'context',
    version = '1.0.0'
  ): void {
    console.log(`dummy send log function with apikey: ${this.apikey}`)
  }
}

export default DummyErrorTracker;