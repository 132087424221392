import {useCallback, useEffect} from "react";
import {useRouter} from "next/router";
import {
  FindDocumentIdDocument,
  FindDocumentIdQuery,
  FindDocumentIdQueryVariables
} from "@/hooks/custom/helpcenter.hooks";
import {useGraphqlFetcher} from '@/config/react-query.config'

const useHandleMessage = (): ((event: MessageEvent) => void) => {
  const { isReady, query, push } = useRouter();
  const pjKey = query.pjKey as string;
  const site = process.env.VERSION_STATUS !== 'preview' ? `${process.env.VERSION_STATUS}` : null;

  const handleGraphQLFetch = useGraphqlFetcher<FindDocumentIdQuery, FindDocumentIdQueryVariables>(FindDocumentIdDocument); // useGraphqlFetcherをカスタムフック内で呼ぶ

  const handleMessage = useCallback(async (event: MessageEvent) => {
    if (!isReady) return;
    
    const payloadValue = event.data?.payload?.payload ?? null;
    const prefix = "document-detail:";
    const prefixLength = prefix.length;
    const isPrefixed = payloadValue?.startsWith(prefix);

    if (isPrefixed) {
      const prefixedValue = payloadValue.slice(prefixLength);

      try {
        const data = await handleGraphQLFetch({
          pjKey,
          id: prefixedValue,
          site
        });

        const Document = data.Documents?.[0] || {};
        const type = Document?.type || '';
        const static_id = Document?.static_id || '';

        if (type.length === 0 || static_id.length === 0) return;

        await push(`/${pjKey}/${type}/${static_id}/`);
      } catch (error) {
        console.error(error);
      }
    }
  }, [isReady, pjKey, site, push, handleGraphQLFetch]); // 必要な依存関係のみを追加する

  return handleMessage;
};

const UseMessageListener = () => {
  const handleMessage = useHandleMessage();

  useEffect(() => {
    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, [handleMessage]);

};

export default UseMessageListener;