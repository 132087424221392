// react
import React, {useEffect, useState} from 'react'
import {useRouter} from 'next/router'

// i18n
import '../locales/i18n';

// scroll bar
import 'simplebar-react/dist/simplebar.min.css';

// lazy image
import 'react-lazy-load-image-component/src/effects/blur.css';

// ----------------------------------------------------------------------

import {CacheProvider, EmotionCache} from '@emotion/react';
// next
import {NextPage} from 'next';
import Head from 'next/head';
import {AppProps} from 'next/app';
// atoms components
import Progress from "@/components/custom/atoms/Progress";
// utils
import {QueryClient, QueryClientProvider, QueryKey} from '@tanstack/react-query';
import {ReactQueryDevtools} from '@tanstack/react-query-devtools';
import UseMessageListener from '@/hooks/custom/useMessageListener';
import {GlobalSettingsProvider} from '@/components/custom/contexts/GlobalSettingsProvider';
import ChatSettings from "@/components/settings/ChatSettings";
import {getVaxToken} from "@/utils/custom/getVaxToken";
// bugsnag component
import { ErrorLogsHelper } from '@/utils/custom/ErrorLogsHelper';
import ErrorBoundary from '../components/custom/organisms/ErrorBoundary';
import createEmotionCache from '../utils/createEmotionCache';
// theme
import ThemeProvider from '../theme';
// locales
// import ThemeLocalization from '../locales';
// components
import ProgressBar from '../components/progress-bar';
import SnackbarProvider from '../components/snackbar';
import { MotionLazyContainer } from '../components/animate';
import { ThemeSettings } from '../components/settings';

// Check our docs
// https://docs.minimals.cc/authentication/ts-version

// ----------------------------------------------------------------------

const clientSideEmotionCache = createEmotionCache();

type NextPageWithLayout = NextPage & {
  getLayout?: (page: React.ReactElement) => React.ReactNode;
};

interface MyAppProps extends AppProps {
  emotionCache?: EmotionCache;
  Component: NextPageWithLayout;
}

// ErrorLogのヘルパークラスをインスタンス化
const LogsHelper = ErrorLogsHelper.createErrorLogsHelper()

export default function MyApp(props: MyAppProps) {
  const { Component, pageProps, emotionCache = clientSideEmotionCache } = props;

  const getLayout = Component.getLayout ?? ((page) => page);

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: false,
        refetchOnWindowFocus: false,
        onSuccess: (data: unknown) => LogsHelper.onSuccess(data),
        onError: (error: unknown) => LogsHelper.onError(error)
      },
      mutations: {
        onSuccess: (data: unknown) => LogsHelper.onSuccess(data),
        onError: (error: unknown) => LogsHelper.onError(error)
      }
    },
  });

  // pjKeyを取得するために必要な関数・変数
  const {isReady} = useRouter()
  const [pjKey, setPjKey] = useState<string>('')

  // pjKeyが取得できる状態を監視
  useEffect(() => {
    if (isReady && pjKey === '') {
      const paths = window.location.pathname.split('/');
      const path = ( paths[1] === 'test' || paths[1] === 'preview') ? paths[2] : paths[1];
      setPjKey(path.toString())
    }
  }, [isReady, pjKey])

  // グローバル関数をセットする
  useEffect(() => {
    window.__getVaxToken__ = getVaxToken;
  }, [])

  // メッセージを受け取るためのカスタムフック
  UseMessageListener();

  // pjKeyが取得できるまでローディングを表示
  if (pjKey === '') {
    return (
      <Progress
        size="100%"
        sx={{
          position: 'fixed',
          width: '100%',
          top: 0,
          left: 0,
        }}
      />
    );
  }

  // ErrorLogsHelperの初期化が複数回起こらないようにする
  if (!LogsHelper.getIsStarted()) LogsHelper.init(pjKey)

  // pjKeyを取得後、メインの処理を実行
  return (
    <CacheProvider value={emotionCache}>
      <Head>
        <meta name="viewport" content="initial-scale=1, width=device-width"/>
      </Head>

      <QueryClientProvider client={queryClient}>
        <ThemeProvider>
          <GlobalSettingsProvider>
            <ThemeSettings pjKey={pjKey}>
              <ChatSettings pjKey={pjKey}>
                <SnackbarProvider>
                  <ProgressBar/>
                  {
                    getLayout(
                      <ErrorBoundary pjKey={pjKey} logsHelper={LogsHelper}>
                        <Component {...pageProps} />
                      </ErrorBoundary>
                    )
                  }
                </SnackbarProvider>
              </ChatSettings>
            </ThemeSettings>
          </GlobalSettingsProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </CacheProvider>
  );
}
