import { useEffect } from 'react';
import {useFindDomainsQuery, useGetChatConfigurationsQuery} from '@/hooks/custom/helpcenter.hooks';
import * as React from "react";
import Script from "next/script";
import process from "process";
import {Storage} from '@/utils/storageHelper';

// contexts

// ----------------------------------------------------------------------

type Props = {
  pjKey: string;
  children: React.ReactNode;
};

declare global {
  interface Window {
    vaxConfig: any;
  }
}

// ----------------------------------------------------------------------

export default function ChatSettings({pjKey, children}: Props) {
  const BOTPRESS_URL = process.env.NEXT_PUBLIC_BOTPRESS_URL;
  const HELP_CENTER_URL = process.env.NEXT_PUBLIC_HELP_CENTER_URL;
  const directusHost = process.env.NEXT_PUBLIC_DIRECTUS_URL;
  // 認証トークンを取得する
  const authTokenStorage = new Storage<any, any>('local', 'auth_token', '');
  const directusUserToken = authTokenStorage.readStorage();
  const SUB_DIR = process.env.VERSION_STATUS !== 'prod' ? `/${process.env.VERSION_STATUS}` : '';

  // チャット設定を取得する
  const versionsFilter = process.env.VERSION_STATUS === 'preview' ? {
    number: {
      _eq: 0
    }
  } : {
    status: {
      _eq: process.env.VERSION_STATUS
    }
  }
  const filter = {
    _and: [
      {
        domain_id: {
          versions: {
            source_id: {
              key: {
                _eq: pjKey as string,
              }
            }
          }
        }
      },
      {
        domain_id: {
          versions: {
            ...versionsFilter
          }
        }
      }
    ]
  }
  const { data, isError, isLoading, isSuccess } = useGetChatConfigurationsQuery({
    filter,
  });

  const domainFilter = {
    _and: [
      {
        versions: {
          source_id: {
            key: {
              _eq: pjKey as string
            }
          }
        }
      },
      {
        versions: {
          ...versionsFilter
        }
      },
      {
        status: {
          _eq: "publish"
        }
      }
    ]
  }
  const { data: domainData, isLoading: isDomainDataLoading } = useFindDomainsQuery({
    filter: domainFilter,
  });

  // 3rt Partyのinject.jsが読み込まれたら、bundle.jsを読み込むステートを定義する
  const [isInjectJsLoaded, setIsInjectJsLoaded] = React.useState(false);

  useEffect(() => {
    if (!data?.ChatConfigurations?.[0]?.chat_title) return;
    if (domainData?.Domains?.[0]?.versions?.[0]?.number === undefined) return;

    window.vaxConfig = {
      userId: "user100",
      host: BOTPRESS_URL,
      directusHost,
      directusUserToken,
      botId: "rdk-dev",
      hideWidget: true,
      extraStylesheet: `${HELP_CENTER_URL}${SUB_DIR}/style/vax.css`,
      showConversationsButton: false,
      enableTranscriptDownload: false,
      botName: data.ChatConfigurations[0].chat_title,
      avatarUrl: `${directusHost}/assets/${data?.ChatConfigurations?.[0]?.chat_bot_avatar?.id}/`,
      composerPlaceholder: data?.ChatConfigurations?.[0]?.chat_placeholder,
      showPoweredBy: false,
      useSessionStorage: true,
      disableNotificationSound: true,
      enablePersistHistory: false,
      versionStatus: process.env.VERSION_STATUS,
      versionNumber: domainData?.Domains?.[0]?.versions?.[0]?.number || null,
      pjKey: pjKey as string,
    }
  }, [data?.ChatConfigurations, BOTPRESS_URL, HELP_CENTER_URL, directusHost, directusUserToken, SUB_DIR, domainData?.Domains, pjKey]);

  if(!data?.ChatConfigurations) return <>{children}</>

  // VAMの設定を読み込み中
  if (isLoading || isError || isDomainDataLoading) return <>{children}</>

  // VAMの設定がない場合
  const configData = data?.ChatConfigurations ?? [];
  if (configData.length === 0) return <>{children}</>

  return (
    <>
      {children}

      {/* Botpress */}
      {pjKey.length > 0 && configData[0]?.is_enabled && configData[0]?.is_hc_displayed &&  (
        <>
          <Script
            src={`${BOTPRESS_URL}/api/v1/bots/rdk-dev/mod/vax/vaw/${pjKey}/va-loader.js?site=${process.env.VERSION_STATUS}`}
            crossOrigin="anonymous"
          />
          <Script src={`${SUB_DIR}/js/bundle.js`} strategy="afterInteractive" />
        </>
      )}
    </>
  );
}
