// @mui
import { Box, BoxProps, LinearProgress } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  color?: 'primary' | 'secondary';
  size?: string;
}

// ----------------------------------------------------------------------

export default function Progress({ color = 'primary', size = '100%', sx }: Props) {
  return (
    <Box sx={{ ...sx }}>
      <LinearProgress color={`${color}`} sx={{ width: `${size}` }} />
    </Box>  
  );
}
