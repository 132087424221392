import merge from 'lodash/merge';
import { useMemo } from 'react';
// @mui
import { ThemeProvider, createTheme, useTheme, alpha } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

//
import { useSettingsContext } from './SettingsContext';

// ----------------------------------------------------------------------

type ColorProps = {
  lighter: string;
  light: string;
  main: string;
  dark: string;
  darker: string;
};

type Props = {
  colorSetting: {
    primary: ColorProps;
    secondary: ColorProps;
  };
  children: React.ReactNode;
};

export default function ThemeContrast({ colorSetting, children }: Props) {
  const outerTheme = useTheme();

  const { themeContrast, themeMode } = useSettingsContext();

  const isLight = themeMode === 'light';

  const isContrastBold = themeContrast === 'bold';

  const themeOptions = useMemo(
    () => ({
      palette: {
        primary: colorSetting.primary,
        secondary: colorSetting.secondary,
        background: {
          ...(isContrastBold && {
            default: isLight ? outerTheme.palette.grey[100] : outerTheme.palette.grey[900],
          }),
        },
      },
      components: {
        MuiButton: {
          styleOverrides: {
            root: {
              '&.MuiButton-contained:hover': {
                boxShadow: `0 8px 16px 0 ${alpha(colorSetting.primary.main, 0.24)}`,
              },
            },
          },
        },
        MuiCard: {
          styleOverrides: {
            ...(isContrastBold && {
              root: {
                boxShadow: outerTheme.customShadows.z4,
              },
            }),
          },
        },
      },
    }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [isLight, themeContrast]
  );

  const theme = createTheme(merge(outerTheme, themeOptions));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
}
