import process from "process";
import {Storage} from "@/utils/storageHelper";
import BugSnagErrorTracker from "@/utils/custom/tracker/BugSnagErrorTracker";
import DummyErrorTracker from "@/utils/custom/tracker/DummyErrorTracker";
import {ErrorTracker} from "@/utils/custom/tracker/ErrorTracker";
import {hasNestedProperty} from "@/utils/custom/utilObjectHelper";

// ----------------------------------------------------------------------
// 目的：エラーログをBugsnagへ送信するためのヘルパークラス
// 機能：Bugsnagの初期化（プロキシサーバー設定含む）、情報取得（訪問者IDなど）、エラーログの送信
// ----------------------------------------------------------------------
export class ErrorLogsHelper {
  private pjkey: string = ''

  private enabled: boolean = process.env.NEXT_PUBLIC_BUGSNAG_ENABLE === 'true' || false;

  private isStarted: boolean = false;

  private errorTracker: ErrorTracker;

  /* ErrorLogsHelperのコンストラクタ
   * @param {ErrorTracker} errorTracker - エラートラッカー
   */
  constructor(errorTracker: ErrorTracker) {
    this.errorTracker = errorTracker;
  }

  /* ErrorTrackerの初期化
   * @param {string}`pjkey` - プロジェクトキー
   */
  public init = (pjkey: string) => {
    // ErrorTrackerクラスの初期化
    this.errorTracker.init();

    //
    this.pjkey = pjkey

    // 初期化が完了したことを記録
    this.isStarted = true
  }

  /* ヘルプセンターの通信成功時の処理
   * @param {any}
   */
  public onSuccess(data: any) {
    // messageがある場合はエラーとして送信する
    if(hasNestedProperty(data, 'parent.message')) {
      this.sendLog('HelpCenter Error Log (onSuccess Event)', data, this.pjkey, data)
    }
  }

  /* ヘルプセンターの通信エラー時の処理
   * @param {unknown}
   */
  public onError(error: unknown) {
    this.sendLog('HelpCenter Error Log (onError Event)', error, this.pjkey, error)
  }

  /* 訪問者IDを取得
   * @return {string} - 訪問者ID
   */
  static getVisitorId = () => {
    const site = process.env.NEXT_PUBLIC_VERSION_STATUS;
    const storageKey = `visitorId-${site}`;
    const storage = new Storage<any, any>('local', storageKey, {
      id: '',
    });
    const visitor = storage.readStorage();

    // 訪問者IDがない場合はからのIDを返す
    if (!visitor.visitorId) {
      return '';
    }

    return visitor.visitorId;
  }

  /* セッションIDを取得
   * @return {string} - セッションID
   */
  static getSessionId = () => {
    const site = process.env.NEXT_PUBLIC_VERSION_STATUS;
    const storageKey = `sessionId-${site}`;
    const storage = new Storage<any, any>('local', storageKey, {
      sessionId: '',
      expires: '',
    });
    const session = storage.readStorage();

    // セッションIDがないか、expiresが過去の場合はからのIDを返す
    if (!session.sessionId || session.expires < new Date().getTime()) {
      return '';
    }

    return session.sessionId;
  }

  /* サイト名を取得
    * @param {string} id - サイト名が書かれたエレメントのID
    * @return {string} - サイト名
   */
  static getTextByID = (id: string) => {
    const element = document.getElementById(id);

    if (element === null) {
      return '';
    }

    return element.textContent || '';
  }

  /* 有効かどうかを取得
    * @return {boolean}
   */
  public getEnabledStatus = () => this.enabled

  /* start関数が呼ばれたかどうかを取得
    * @return {boolean}
   */
  public getIsStarted = () => this.isStarted

  /* エラーログを送信
    * @param {string} name - エラー名
    * @param {any} data - エラー情報
    * @param {string} pjkey - プロジェクトキー
    * @param {any} message - エラーメッセージ
    * @param {string} section - セクション
    * @param {string} version - バージョン
   */
  public sendLog = (
    logName: string,
    data:any,
    pjkey:string = '',
    message:any = {
      "name": "Error",
    },
    section = 'context',
    version = '1.0.0'
  ) => {
      const NEXT_PUBLIC_GIT_REV = process.env.NEXT_PUBLIC_GIT_REV || version
      const visitorId = ErrorLogsHelper.getVisitorId();
      const sessionId = ErrorLogsHelper.getSessionId();
      const siteName = ErrorLogsHelper.getTextByID('hcSiteName');
      const dataString = JSON.stringify(data);

      this.errorTracker.sendLog(
        logName,
        dataString,
        pjkey,
        visitorId,
        sessionId,
        siteName,
        message,
        section,
        NEXT_PUBLIC_GIT_REV
      )
  }

  /* エラーログヘルパーの生成
    * @return {ErrorLogsHelper}
   */
  static createErrorLogsHelper = () => {
    const USE_BUGSNAG_TRACKING = process.env.NEXT_PUBLIC_BUGSNAG_ENABLE === 'true' || false;
    return new ErrorLogsHelper(USE_BUGSNAG_TRACKING ? new BugSnagErrorTracker() : new DummyErrorTracker());
  }
}

