/**
 * ストレージ管理のヘルパークラス
 */
export class Storage<T, U> {
  type: string;

  key: string;

  returnDefault: any;

  storage:
    | {
        getItem: any;
        setItem: any;
      }
    | undefined;

  constructor(type: string, key: string, returnDefault: any) {
    this.type = type;
    this.key = key;
    this.returnDefault = returnDefault;
    this.storage = undefined;
  }

  // サーバーサイトでストレージにアクセスするとエラーになるのでconstructor時点ではストレージ変数は定義しない
  generateStorage() {
    if (this.storage === undefined) {
      this.storage = this.type === 'local' ? localStorage : sessionStorage;
    }
    return this.storage;
  }

  readStorage(): T | U {
    const storage = this.generateStorage();
    const item = storage.getItem(this.key);
    if (item === null) {
      return this.returnDefault;
    }
    if (this.isJsonString(item)) {
      return JSON.parse(item);
    }
    return item;
  }

  writeStorage(item: T) {
    const storage = this.generateStorage();
    storage.setItem(this.key, JSON.stringify(item));
  }

  /**
   * 値がJSONであるかどうかを判定する
   */
  isJsonString(str: string) {
    try {
      JSON.parse(str);
    } catch (e) {
      console.log(this);
      return false;
    }
    return true;
  }
}
