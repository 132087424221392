/**
 * 色を明るくする
 * @param color ベースカラー
 * @param amount いくつ明るくするか
 */
export const lightenColor = (color: string, amount: number) => {
  const parsedColor = parseInt(color.replace('#', ''), 16);
  const redAmount = Math.min(255, Math.max(0, Math.round(parsedColor / 0x10000 + amount)));
  const greenAmount = Math.min(
    255,
    Math.max(0, Math.round(((parsedColor / 0x100) % 0x100) + amount))
  );
  const blueAmount = Math.min(255, Math.max(0, Math.round((parsedColor % 0x100) + amount)));
  const r = redAmount < 16 ? `0${redAmount.toString(16)}` : `${redAmount.toString(16)}`;
  const g = greenAmount < 16 ? `0${greenAmount.toString(16)}` : `${greenAmount.toString(16)}`;
  const b = blueAmount < 16 ? `0${blueAmount.toString(16)}` : `${blueAmount.toString(16)}`;
  return `#${r}${g}${b}`;
};
/**
 * 色を暗くする
 * @param color ベースカラー
 * @param amount いくつ暗くするか
 */
export const darkenColor = (color: string, amount: number) => lightenColor(color, amount * -1);

/**
 * paletteのprimaryとsecondaryの色を変更する
 * @param primaryColor プライマリカラー
 * @param secondaryColor セカンダリカラー
 */
export const changePaletteColor = (primaryColor: string, secondaryColor: string) => {
  // ヘルプセンターでは基本的にdarkとmainしか使われないため、amountはdarkの値のみもとのテンプレになるべく合わせる値にしている

  const primary = {
    lighter: lightenColor(primaryColor, 100),
    light: lightenColor(primaryColor, 48),
    main: primaryColor,
    dark: darkenColor(primaryColor, 48),
    darker: darkenColor(primaryColor, 100),
  };

  const secondary = {
    lighter: lightenColor(secondaryColor, 100),
    light: lightenColor(secondaryColor, 74),
    main: secondaryColor,
    dark: darkenColor(secondaryColor, 74),
    darker: darkenColor(secondaryColor, 100),
  };

  return {
    primary,
    secondary,
  };
};
