// @mui
import { Stack, StackProps } from '@mui/material';

//
import AlertDialog from '@/components/custom/atoms/AlertDialog';

// ----------------------------------------------------------------------

interface Props extends StackProps {
  error: 0 | 1 | 2;
  size?: string;
}

/**
 * 0: 画面エラー（主に画面で必要なAPIとのやり取りでエラーとなる場合）
 */
export const ERROR = {
  0: '大変申し訳ございません。通信エラーが発生しました。',
  1: '大変申し訳ございません。管理者へお問い合わせください。',
  2: '申し訳ありませんが、予期せぬエラーが発生しました。もう一度お試しいただくか、サイトトップへお戻りください。',
};

// ----------------------------------------------------------------------

export default function ErrorAlertDialog({ size = '100%', error, sx }: Props) {
  const ERROR_TITLE = 'Error';
  return (
    <Stack sx={{ width: `${size}`, ...sx }}>
      <AlertDialog title={ERROR_TITLE} text={ERROR[error]} />
    </Stack>
  );
}
