// @mui
import { Alert, AlertTitle } from '@mui/material';

// ----------------------------------------------------------------------

type Props = {
  title: string;
  text: string;
  status?: 'error' | 'warning' | 'info' | 'success';
};

// ----------------------------------------------------------------------

export default function AlertDialog({ title, text, status = 'error' }: Props) {
  return (
    <Alert severity={`${status}`}>
      <AlertTitle>{title}</AlertTitle>
      {text}
    </Alert>
  );
}
