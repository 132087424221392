import { useContext, useEffect } from 'react';
import Head from 'next/head';
//
// import ThemeRtlLayout from './ThemeRtlLayout';
// import ThemeColorPresets from './ThemeColorPresets';
// import SettingsDrawer from './drawer';
import { SiteConfigurations, useGetSiteConfigurationsQuery } from '@/hooks/custom/helpcenter.hooks';
import { changePaletteColor } from '@/utils/custom/createColorHelpter';
import { GlobalSettingsContext } from '@/components/custom/contexts/GlobalSettingsProvider';
import process from "process";
import ThemeContrast from './ThemeContrast';

// contexts

// ----------------------------------------------------------------------

const directusHost = process.env.NEXT_PUBLIC_DIRECTUS_URL;

type Props = {
  pjKey: string;
  children: React.ReactNode;
};

// ----------------------------------------------------------------------

export default function ThemeSettings({pjKey, children}: Props) {

  //
  const versionsFilter = process.env.VERSION_STATUS === 'preview' ? {
    number: {
      _eq: 0
    }
  } : {
    status: {
      _eq: process.env.VERSION_STATUS
    }
  }
  const filter = {
    _and: [
      {
        domain_id: {
          versions: {
            source_id: {
              key: {
                _eq: pjKey
              }
            }
          }
        }
      },
      {
        domain_id: {
          versions: {
            ...versionsFilter
          }
        }
      }
    ]
  }
  const { data, isLoading, isSuccess, isError } = useGetSiteConfigurationsQuery({ filter });
  const { dispatch } = useContext(GlobalSettingsContext);
  //
  const siteConfig = (data?.SiteConfigurations && data?.SiteConfigurations[0] as SiteConfigurations) ?? {};
  const primaryColor = (siteConfig?.color_main as string) ?? '';
  const secondaryColor = (siteConfig?.color_sub as string) ?? '';
  const icon = (siteConfig?.icon?.id as string) ?? '';
  // VAMの設定で上書きする色値を生成
  const colorSetting = changePaletteColor(primaryColor, secondaryColor);

  // 初期設定をGlobalSettingsContextに保存
  useEffect(() => {
    dispatch({
      type: 'success',
      payload: {
        is_enabled: siteConfig?.is_enabled ?? false,
        is_public: siteConfig?.is_public ?? false,
        title: siteConfig?.title ?? '',
        icon: siteConfig?.icon?.id ?? '',
        logo: siteConfig?.logo?.id ?? '',
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);

  if (!data?.SiteConfigurations) return null

  // VAMの設定を読み込み中
  if (isLoading || isError) return null

  // VAMの設定がない場合
  const configData = data?.SiteConfigurations ?? [];
  if (configData.length === 0) return null

  return (
    <>
      <Head>
        <link rel="icon" href={`${directusHost}/assets/${icon}`} />
      </Head>
      <ThemeContrast colorSetting={colorSetting}>
        {/* <ThemeColorPresets> */}
        {/* <ThemeRtlLayout> */}
        {children}
        {/* <SettingsDrawer /> */}
        {/* </ThemeRtlLayout> */}
        {/* </ThemeColorPresets> */}
      </ThemeContrast>
    </>
  );
}
